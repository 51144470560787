import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ListItem from "./components/ListItem";
import { tableColumn2 } from "./components/data";
import { Grid, Typography } from "@mui/material";

import { Table } from "antd";
import { useEffect, useState } from "react";
import Apis from "apis/remotes";
import { IExpWorkUser, IExpWorkDayList } from "apis/response";
import { useNavigate, useParams } from "react-router-dom";
import { calculateAge } from "utils/utilDate";
import STcomp from "components/STcomp";
import MDImage from "components/MDImage";
import RenderUserScore from "components/RenderUserScore";
import { genderString } from "utils";
import { inputToPhone } from "utils/PhoneString";
import MDTypography from "components/MDTypography";

function WorkerDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState<IExpWorkUser>();
  const [expWorks, setExpWorks] = useState<IExpWorkDayList[]>([]);

  useEffect(() => {
    Apis.getExpWorkListById(id)
      .then((resolve) => {
        setUser(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /** 근로 내역 상세 조회 API
     * 추가할 파라미터 : 해당 현장 누적 출근 횟수 | 근로유형 | 정산 금액
     */
    Apis.getExpWorkListDayById(id)
      .then((resolve) => {
        setExpWorks(resolve.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <MDBox display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  <MDImage
                    image={user?.profile}
                    width={"100%"}
                    style={{ maxWidth: 300, objectFit: "contain" }}
                    height={300}
                  />
                  <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
                    <MDBox display={"flex"} alignItems={"center"}>
                      <Typography>{RenderUserScore(user?.userScore)}</Typography>
                    </MDBox>

                    <Typography fontWeight={700}>{user?.name}</Typography>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <MDBox>
                  <ListItem label={"이름"} values={user?.name} />
                  <ListItem label={"성별"} values={genderString(user?.gender ?? "")} />
                  <ListItem label={"나이"} values={calculateAge(user?.birth ?? "")} />
                  <ListItem label={"연락처"} values={inputToPhone(user?.phone ?? "")} />
                  <ListItem
                    label={"비상연락처"}
                    values={inputToPhone(user?.emergencyPhone ?? "")}
                  />
                  <ListItem label={"경력정보"} values={user?.occupation + " : " + user?.career} />
                  <ListItem
                    label={"혈압정보"}
                    values={`이완: ${user?.bloodPressureDia} | 수축: ${user?.bloodPressureSys}`}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <MDBox display={"flex"} flexDirection={"column"} alignItems={"center"}>
                      <MDTypography>신분증</MDTypography>
                      <MDImage
                        image={user?.idCard}
                        width={"100%"}
                        style={{ maxWidth: 300, objectFit: "contain" }}
                        height={300}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox display={"flex"} flexDirection={"column"} alignItems={"center"}>
                      <MDTypography>안전교육 이수증</MDTypography>
                      <MDImage
                        image={user?.safetyCertificate}
                        width={"100%"}
                        style={{ maxWidth: 300, objectFit: "contain" }}
                        height={300}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox borderRadius="12px" width={"100%"} mt={2} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={4} md={2}>
                <STcomp title={"지원 횟수"} amount={user?.applyCount} variant="contained" />
              </Grid>
              <Grid item xs={4} md={2}>
                <STcomp title={"근로 횟수"} amount={user?.workCount} variant="contained" />
              </Grid>
              <Grid item xs={4} md={2}>
                <STcomp title={"결근 횟수"} amount={user?.absenceCount} variant="contained" />
              </Grid>
            </Grid>
          </MDBox>

          <Table
            rowKey={(record) => record.userApplicantId}
            columns={tableColumn2}
            dataSource={expWorks}
            scroll={{ x: "max-content" }}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/announcement-management/site-details/${record.workId}`);
                },
              };
            }}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default WorkerDetails;
